import React, { useCallback } from 'react'
import cn from 'classnames'
import s from './Button.module.scss'
import { useRouter } from 'next/router'
import { ArrowRight } from '@/icons'
import { Loading } from '@/icons'
import useLocale from '@/hooks/useLocale'

type Iprops = {
  className?: string
  isShowH5?: boolean //h5是否展示
  link?: string
  text?: string
  isFade?: boolean
  theme?: string // dark  light
  type?: 'Arrow' | 'smArrow' | 'linear' | 'primary' | 'secondary' | 'green'
  arrow?: boolean
  onClick?: (e: any) => void
  onDblClick?: (e: any) => void
  needAos?: boolean
  isLoading?: boolean
  target?: '_blank' | '_self'
  size?: 'big' | 'middle' | 'small'
  disabled?: boolean
}
const Button = (props: Iprops) => {
  const { text = '', isShowH5 = true, link = '', theme = '', className = '', type = 'Arrow', needAos = true, isLoading = false, target = '_self', arrow = true, size = 'big', disabled = false } = props
  const router = useRouter()
  const { t } = useLocale()
  const onClickButton = useCallback(
    (e: any) => {
      if (isLoading) return;
      if (!link) {
        props.onClick && props.onClick(e)
        return
      }
      if (link.indexOf('http') != -1 || target == '_blank') {
        let a = ''
        if (link.indexOf('http') == -1) {
          a = router.locale == 'en' ? '' : `/${router.locale}`
        }
        window.open(a + link, '_blank')
      } else {
        router.push(link)
      }
    },
    [isLoading, link, props, router, target]
  )
  const onDblClickButton = useCallback(
    (e: any) => {
      if (isLoading) return;

      if (!link) {
        props.onDblClick && props.onDblClick(e)
        return
      }
      if (link.indexOf('http') != -1 || target == '_blank') {
        window.open(link, '_blank')
      } else {
        router.push(link)
      }
    },
    [isLoading, link, props, router, target]
  )

  return (
    <button
      className={cn(
        s.button,
        s[`${size}Button`],
        s[`button${type}`],
        { [s.loadingButton]: isLoading, [s.bottonHidden]: !isShowH5, [s.bottonDeep]: type == 'Arrow' && theme == 'dark', [s.shallow]: theme == 'light' },
        className
      )}
      onClick={onClickButton}
      onDoubleClick={onDblClickButton}
      disabled={disabled}
      data-aos={needAos && !isLoading ? 'fade-up' : ''}
    >
      {isLoading ? (
        <span className={s.loadingText}>
          <Loading className={s.loading}></Loading>
          {t('Submitting')}
        </span>
      ) : (
        <>
          {text} {arrow && <ArrowRight size="16" className={cn(s.arrow, { [s.arrowAr]: router.locale == 'ar' })} />}{' '}
        </>
      )}
    </button>
  )
}
export default Button
